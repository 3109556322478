const { buildBusinessManagerUrl } = require('../utils/urlBuilders');

let metaSiteId;

function initAppForPage(...args) {
  metaSiteId = args[3].bi.metaSiteId;
}

async function pageReady($w, wix, config) {
  const { pageId } = config;
  AuthenticateBusinessManager(wix, pageId);
}

async function notifyBizMgr(wix, pageId) {
  if (wix.window.getContainerPort) {
    // Bolt
    try {
      const port = await wix.window.getContainerPort();
      port.postMessage({ isLoaded: true, pageId });
    } catch (e) {
      console.log(e);
    }
  } else {
    wix.dashboard.notifyListeners('admin-pages.pageUpdated', { pageId });
  }
}

function redirectToBizMgr(wix, metasiteId, pageId) {
  wix.location.to(buildBusinessManagerUrl(metaSiteId, pageId));
}

function AuthenticateBusinessManager(wix, pageId) {
  wix.location.query.inBizMgr
    ? notifyBizMgr(wix, pageId)
    : redirectToBizMgr(wix, metaSiteId, pageId);
}

function createControllers(controllerConfigs) {
  return controllerConfigs.map(controllerConfig => {
    return {
      pageReady: ($w, Wix) => pageReady($w, Wix, controllerConfig.config),
    };
  });
}

module.exports = {
  initAppForPage,
  createControllers,
};
