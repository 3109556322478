const QUERY_CHAR_INDEX = 1;

export function getQueryParams() {
  return window.location.search
    .substring(QUERY_CHAR_INDEX)
    .split('&')
    .map(param => param.split('='))
    .reduce((total, [key, value]) => {
      total[key] = value;
      return total;
    }, {});
}

const getStaticsUrl = () => window.__STATICS_BASE_URL__;

export const getBaseUrl = (adminPagesScriptUrl: string) => {
  const url = adminPagesScriptUrl.match(/((?:\/[^\/]+)+)(?=\/[^\/]+)/);
  return `https://${(url && url.length && url[0].substring(1)) || ''}/`;
};
export const getImagesStaticsUrl = () => getStaticsUrl() + 'assets/images/';

export const buildBusinessManagerUrl = (metasiteId, pageId) =>
  `https://www.wix.com/dashboard/${metasiteId}/admin-pages/${pageId}?referralInfo=sidebar`;
